import { Divider } from "@mui/material"
import React from "react"
import { UILink } from "../../components"

const thanks = () => (
  <div className="container">
    <div className="thankyou">
      <Divider flexItem={true}>
        <h1>Thank you!</h1>
        <UILink to="/">
          <span className="text--light">Go Home</span>
        </UILink>
      </Divider>
    </div>
  </div>
)

export default thanks
